<template>
  <div class="modal fade" :id="modalName" tabindex="-1" role="dialog" :aria-labelledby="modalName"
       aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Response</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="row d-flex justify-content-center" v-if="customerOpinion?.customerOpinionResponses?.length > 0">
              <div class="col-12 col-md-12" v-for="(opinionResponse,index) in customerOpinion?.customerOpinionResponses"
                   :key="index">
                <div class="d-flex ">
                  <div class="ml-1">
                    <h5 class="list-title mb-0 font-weight-bold">
                      Question: {{ opinionResponse.question }}
                    </h5>

                    <p class="font-small-3"> Answer: {{ opinionResponse.answer }}</p>
                  </div>
                </div>
                <hr>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button type="button" class="btn btn-primary" data-dismiss="modal">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Close</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowCustomerOpinion",
  props: {
    modalName: {
      type: String,
      required: true,
    },
    customerOpinion: {
      type: Object,
      required: true,
      default: () => ({})
    }

  },
  data() {
    return {}
  }

}
</script>

<style scoped>

</style>