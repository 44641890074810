<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0">
          <h2 class="">Customer Opinions List</h2>

        </div>
        <div class="users-list-filter px-1">
          <div class="row border rounded py-2 mb-2 align-items-center">
            <div class="col-12 col-md-4 mb-1 mb-sm-0">
              <input v-model="getCustomerOpinionsParams.reference" class="form-control search-product-input-element"
                     type="text"
                     placeholder="Search a reference..."/>
            </div>

            <div class="col-12 col-md-3 mb-1 mb-sm-0">
              <button @click="applyCustomerOpinionsFilter(null)"
                      class="btn btn-primary btn-block glow users-list-clear mb-0">
                Search
              </button>
            </div>
          </div>
        </div>
        <div class="users-list-table" ref="serviceCategoriesListRef">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{ width: '20%' }">NAME</th>
                    <th class="position-relative" :style="{ width: '20%' }">RATING</th>
                    <th class="position-relative" :style="{ width: '20%' }">REFERENCE</th>
                    <th class="position-relative" :style="{ width: '20%' }">COMMENT</th>
                    <th class="position-relative" :style="{ width: '20%' }">STATUS</th>
                    <th class="position-relative" :style="{ width: '10%' }">ACTIONS</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(customerOpinion, key) in customerOpinions" :key="key">
                    <td>
                      {{ userFullAddress(customerOpinion.user.customer) }}
                    </td>
                    <td>
                      {{ customerOpinion.rating }}
                    </td>
                    <td>
                      {{ customerOpinion.reference }}
                    </td>
                    <td>
                      {{ customerOpinion.comment }}
                    </td>
                    <td>
                      <span
                          :class="customerOpinion.status==='In Review'? 'badge badge-light-warning badge-pill':
                          (customerOpinion.status==='Published'? 'badge badge-light-success badge-pill':
                           'badge badge-light-danger badge-pill')">
                        {{ customerOpinion.status }}
                      </span>
                    </td>

                    <td>
                      <div class="d-flex align-items-top justify-content-around">
                        <button
                            class="m-0 p-0 bg-transparent text-decoration-none cursor-pointer border-0 view-edit-delete-icon"
                            :disabled="!can('customer-opinion-view')"
                            :title="can('customer-opinion-view') ? 'Update' : 'Update forbidden'"
                            :style="{height:'18px'}"
                            @click="showSingleCustomerOpinion(customerOpinion.id)">
                          <i class="bx bx-show"></i>
                        </button>

                        <div class="dropdown py-0 px-0 view-edit-delte-icon" :style="{height:'18px'}">
                                                    <span type="button" class="px-0 py-0" data-toggle="dropdown"
                                                          aria-haspopup="true" aria-expanded="false">
                                                        <i class='bx bx-dots-vertical-rounded h-100'></i>
                                                    </span>
                          <div class="dropdown-menu" style="min-height: 100px">


                            <button @click="modelIdForDelete = customerOpinion.id" data-toggle="modal"
                                    :disabled="!can('customer-opinion-delete')"
                                    :title="can('customer-opinion-delete') ? 'Delete' : 'Delete forbidden'"
                                    data-target="#deleteCustomerOpinionModal" class="dropdown-item">Delete
                            </button>

                            <button class="dropdown-item" @click="changeCustomerOpinionStatus(customerOpinion.id)"
                                    :disabled="!can('customer-opinion-update')"
                                    :title="can('customer-opinion-update') ? 'Update' : 'Update forbidden'">
                              Change Status
                            </button>

                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <ListPagination position="right" :pagination-data="paginateLinks"
                                @getClickedPage="applyCustomerOpinionsFilter"/>
              </div>
            </div>
          </div>
        </div>

        <div class="" data-toggle="modal" data-target="#updateStatusModal"></div>
        <div class="" data-toggle="modal" data-target="#showCustomerOpinion"></div>

        <DeleteAlertModal modal-name="deleteCustomerOpinionModal"
                          model-name="customerOpinion"
                          :model-id="modelIdForDelete"
                          @confirmModelDeletion="onConfirmModelDeletion"/>

        <UpdateStatusModal :customer-opinion="{...selectedCustomerOpinionDetails}" @updatedData="onUpdatedData"/>
        <ShowCustomerOpinion :customer-opinion="{...selectedCustomerOpinionDetails}" modal-name="showCustomerOpinion"/>
      </section>
    </template>
  </AppLayout>
</template>

<script>
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import AppLayout from "@/layouts/backEnd/AppLayout";
import ListPagination from "@/components/backEnd/pagination/ListPagination";
import DeleteAlertModal from "@/components/backEnd/modal/DeleteAlertModal";
import {mapActions, mapGetters} from "vuex";
import UpdateStatusModal from "@/views/backEnd/customerOpinions/includes/UpdateStatusModal";
import ShowCustomerOpinion from "@/views/backEnd/customerOpinions/includes/ShowCustomerOpinion";
import Authorization from "@/components/backEnd/mixins/Authorization";

export default {
  name: "CustomerOpinionList",
  mixins: [ShowToastMessage, Loader, Authorization],
  components: {
    AppLayout,
    DeleteAlertModal,
    ListPagination,
    UpdateStatusModal,
    ShowCustomerOpinion,
  },
  data() {
    return {
      changeStatusCustomerOpinionId: '',
      modelIdForDelete: '',
      getCustomerOpinionsParams: {
        reference: '',
        with_relation: ['user.customer.address.business', 'customerOpinionResponses'],
        order_by_id: "DESC",
        paginate: 1,
        pagination: "",
      },
      selectedCustomerOpinionDetails: {},
    };
  },
  computed: {
    ...mapGetters({
      customerOpinions: "appCustomerOpinions/customerOpinions",
      paginateLinks: "appCustomerOpinions/paginateLinks",
    }),
  },
  methods: {
    ...mapActions({
      getCustomerOpinions: "appCustomerOpinions/getCustomerOpinions",

      putCustomerOpinion: "appCustomerOpinions/putCustomerOpinion",

      deleteCustomerOpinion: "appCustomerOpinions/deleteCustomerOpinionOnList",
    }),

    userFullAddress(customerObj) {
      let businessName = customerObj?.address?.business?.name ?? '';
      let addressStreet = customerObj?.address?.street ?? '';
      let addressSuburb = customerObj?.address?.suburb ?? '';
      let addressState = customerObj?.address?.state.toUpperCase() ?? '';
      let addressPostCode = customerObj?.address?.post_code ?? '';
      let addressCountry = customerObj?.address?.country ?? '';
      return customerObj?.type === 'Business' ?
          `${businessName} (${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry})` :
          `${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`;
    },

    onUpdatedData(data) {
      let {id, status} = data
      const index = this.customerOpinions.findIndex((cus) => cus.id === id);
      if (index !== -1) {
        this.customerOpinions[index].status = status;
      }
    },

    async toggleModal(modalName) {
      document.querySelector(`[data-target="#${modalName}"]`).click(); // modal close
    },

    async changeCustomerOpinionStatus(id) {
      await this.getSingleCustomerOpinionFromObj(id);
      await this.toggleModal('updateStatusModal')
    },

    async getCustomerOpinionsList(getCustomerOpinionsParams) {
      this.loader(true);
      await this.getCustomerOpinions(getCustomerOpinionsParams).then(
          (response) => {
            if (response && response.message && response.status !== 200) {
              this.showToastMessage(response);
            }
          }
      );
      this.loader(false);
    },

    async applyCustomerOpinionsFilter(pageNumber) {
      this.getCustomerOpinionsParams.page = pageNumber;
      await this.getCustomerOpinionsList(this.getCustomerOpinionsParams);
    },

    async getSingleCustomerOpinionFromObj(id) {
      this.selectedCustomerOpinionDetails = {};
      this.selectedCustomerOpinionDetails = await this.customerOpinions.find((item) => item.id === id);
    },

    async showSingleCustomerOpinion(id) {
      await this.getSingleCustomerOpinionFromObj(id);
      await this.toggleModal('showCustomerOpinion');
    },

    async deleteSingleCustomerOpinionOnList(id) {
      this.deleteCustomerOpinion(id).then(async (response) => {
        if (response.status === 200 || response.status === 204) {
          this.showToastMessage({type: 'success', message: 'customer opinion deleted successfully'});
          return;
        }
        this.showToastMessage(response);
      });
    },

    async onConfirmModelDeletion(confirmModelDeletion) {
      await this.deleteSingleCustomerOpinionOnList(confirmModelDeletion.modelId);
    },

    async updateCustomerOpinionStatus(id, status) {
      let dataObj = {
        id: id,
        data: {
          status: status,
        },
      };
      this.putCustomerOpinion(dataObj).then(async (response) => {
        if (response.status === 200) {
          this.status = this.customer.status;
          let toastObj = {message: 'Customer opinion status updated', type: 'success'};
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      });
    },

  },

  mounted() {
    this.getCustomerOpinionsList(this.getCustomerOpinionsParams);
  },
}
</script>

<style scoped>

</style>