<template>
  <div class="modal fade " id="updateStatusModal" tabindex="-1" role="dialog" aria-labelledby="updateStatusModal"
       aria-hidden="true">
    <div class="modal-dialog  modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Change Status</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="row">
              <div class="col-12">
                <h4 class="text-dark ">Status*</h4>
                <div class="form-group">
                  <div class="controls">
                    <VueMultiselect :allow-empty="false" v-model="selectedStatus" class="multiselect-blue"
                                    :options="statusOptions"
                                    :close-on-select="true" label="name" track-by="name" :show-labels="false"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button @click="updateCustomerOpinionStatus" type="button" class="btn btn-primary">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Save</span>
          </button>

        </div>
        <div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";

export default {
  name: "UpdateStatusModal",
  mixins: [Loader, ShowToastMessage],
  props: ['customerOpinion'],
  emits: ['updatedData'],
  data() {
    return {
      status: '',
      selectedStatus: {
        name: 'None',
        value: ''
      },
      statusOptions: [
        {
          name: 'None',
          value: ''
        },
        {
          name: 'Published',
          value: 1
        },
        {
          name: 'Unpublished',
          value: 2
        },

      ],
    }
  },
  watch: {
    customerOpinion(currentValue) {
      if (currentValue.status === 'Published') {
        this.selectedStatus = this.statusOptions[1]
      }
      if (currentValue.status === 'Unpublished') {
        this.selectedStatus = this.statusOptions[2]
      }
    },
    selectedStatus() {
      this.status = this.selectedStatus.value;
      // after change  order status
    },
  },
  methods: {
    ...mapActions({
      putCustomerOpinionStatus: 'appCustomerOpinions/putCustomerOpinion',
    }),

    async updateCustomerOpinionStatus() {
      /*if (this.isStatusChange === false) {
        this.errors.status = 'Please Change the Status First'
        setTimeout(() => this.errors = {}, 4000);

        return
      }*/
      const dataObj = {
        id: this.customerOpinion.id,
        data: {
          status: parseInt(this.status),
        }
      }
      this.loader(true);
      await this.putCustomerOpinionStatus(dataObj).then((response) => {
        if (response.status === 200) {
          document.querySelector('[data-target="#updateStatusModal"]').click();
          this.$emit('updatedData', {id: this.customerOpinion.id, status: this.selectedStatus.name});
          this.loader(false);
          this.resetData();
          this.showToastMessage({
            type: 'success',
            message: 'customer opinion has updated.'
          });
        }
        // error goes here

        if (response.message) {
          this.showToastMessage(response);
        }
      });

    },

    resetData() {
      Object.assign(this.$data, this.$options.data.apply(this));
    }
  }
}
</script>

<style scoped>

</style>